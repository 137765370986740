.modal_overlay {
  @apply bg-modal inset-0 fixed;

  z-index: 999;
}

.modal_content {
  @apply rounded-xl overflow-hidden absolute right-auto bottom-auto bg-white top-1/2 left-1/2;

  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 910px;
}

.conversion_log_wrapper {
  max-height: 60vh;
  overflow-y: auto;
}
