/* ./src/index.css */
@import 'assets/fonts/font.css';

html,
body,
#root {
  height: 100%;
}

.content {
  padding-top: 70px;
  min-height: 100%;
}

.tooltip {
  @apply invisible absolute;
}

.has-tooltip:hover .tooltip {
  @apply visible z-50;
}

@tailwind base;

@layer base {
  body {
    font-family: Avenir, Roboto, Arial, serif;
  }
  h1 {
    @apply text-3xl;
  }
  h2 {
    @apply text-2xl;
  }
  h3 {
    @apply text-xl;
  }
  a {
    @apply text-blue-600 underline;
  }
}

@layer utilities {
  .callout {
    padding: 0.875rem 1rem;
    margin: 20px 0;
    background: #e7f5f2;
    border-left-width: 3px;
    border-left-color: #45b26b;
    color: #45b26b;
    border-radius: 4px;
  }

  .flex-center {
    @apply flex items-center justify-center;
  }
}

@tailwind components;
@tailwind utilities;

.font-avenir {
  font-family: Avenir, Roboto, Arial, serif !important;
}
