.onboarding_page {
  width: 60.75rem;
  height: 37.5rem;
  max-width: 100%;

  .steps {
    @apply p-10;
  }
}

.divider {
  background: repeating-linear-gradient(
      to bottom,
      transparent 0 4px,
      #a6b0c2 4px 8px
    )
    0%/2px 100% no-repeat;
}

.modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
}

.modal_content {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 60.75rem;
}
