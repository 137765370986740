.modal_overlay {
  @apply bg-modal;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
}

.modal_content {
  @apply rounded-xl;
  @apply overflow-hidden;
  @apply outline-none;
  @apply shadow-sm;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  padding: 2rem;
  transform: translate(-50%, -50%);
  width: 30rem;
  background: white;
}
