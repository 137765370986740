.modal_overlay {
  @apply overflow-auto;
  @apply bg-modal;
  @apply inset-0;

  position: fixed;
  z-index: 999;
}

.modal_content {
  @apply rounded-xl;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 900px;
  margin-top: 20px;
  background: white;
}

.custom_help_icon {
  margin-left: 19.25rem;
  margin-top: -1.4rem;
}
